.deal-container[data-v-2e917d81] {
  width: 100%;
  height: 100%;
  background-color: #e7ebee;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: var(--rootFontSize);
}
.deal-container .deal-header[data-v-2e917d81] {
    width: 100%;
    height: 100px;
    padding: 0px 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    background-color: #fff;
}
.deal-container .deal-header .deal-title[data-v-2e917d81] {
      height: 30px;
      margin: 0 auto;
      line-height: 30px;
      margin-top: 15px;
      font-size: var(--rootFontSize3);
      color: #0270c1;
      font-weight: 700;
}
.deal-container .deal-header .deal-tabs[data-v-2e917d81] {
      height: 30px;
      line-height: 30px;
      margin: 0 auto;
      margin-top: 20px;
      font-size: var(--rootFontSize2);
      font-weight: 600;
      color: #303133;
      cursor: pointer;
}
.deal-container .deal-header .deal-tabs span[data-v-2e917d81] {
        cursor: pointer;
        padding-bottom: 5px;
}
.deal-container .deal-header .deal-tabs span.actived[data-v-2e917d81] {
          border-bottom: 2px solid transparent;
          color: #0270c1;
}
.deal-container .deal-header .deal-tabs span[data-v-2e917d81]:last-child {
          margin-left: 50px;
}
.deal-container .deal-main[data-v-2e917d81] {
    height: calc(100% - 120px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 0px 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
.deal-container .deal-main .deal-content[data-v-2e917d81] {
      margin-top: 10px;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
}
.deal-container .deal-main .deal-content .deal-form[data-v-2e917d81] {
        width: 100%;
        height: calc(100% - 60px);
        overflow-y: auto;
}
.deal-container .deal-main .deal-content .deal-btns[data-v-2e917d81] {
        position: fixed;
        bottom: 0;
        margin-top: 10px;
        width: 100%;
        height: 50px !important;
        line-height: 50px !important;
        text-align: center;
        background-color: #fff;
        border: 1px solid transparent;
}
.deal-container .deal-main .deal-content .deal-btns .deal-btn-item[data-v-2e917d81] {
          height: 32px;
          padding: 4px 14px;
          margin-left: 20px;
}
.deal-container .deal-main .deal-content .deal-tools[data-v-2e917d81] {
        position: absolute;
        bottom: 39px;
        left: 50px;
        z-index: 2;
        padding: 10px 0;
}
.deal-container .deal-main .deal-content .deal-flow[data-v-2e917d81] {
        width: 100%;
        height: 100%;
        outline: none;
}
.deal-container .deal-main .deal-logs[data-v-2e917d81] {
      height: 100%;
      padding: 10px 14px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      background-color: #fff;
      margin: 10px;
      margin-right: 0px;
      border-radius: 3px;
}
@media (max-width: 1599px) {
.deal-header[data-v-2e917d81] {
    width: 100%;
}
.deal-header .deal-title[data-v-2e917d81] {
      width: 100%;
}
.deal-header .deal-tabs[data-v-2e917d81] {
      width: 100%;
}
.deal-main[data-v-2e917d81] {
    width: 100%;
}
.deal-main .deal-content[data-v-2e917d81] {
      width: calc(100% - 300px);
}
.deal-main .deal-content .deal-title[data-v-2e917d81] {
        width: 100%;
}
.deal-main .deal-content .deal-tabs[data-v-2e917d81] {
        width: 100%;
}
.deal-main .deal-logs[data-v-2e917d81] {
      width: 300px;
}
}
@media (min-width: 1600px) {
.deal-header[data-v-2e917d81] {
    width: 100%;
}
.deal-header .deal-title[data-v-2e917d81] {
      width: 100%;
}
.deal-header .deal-tabs[data-v-2e917d81] {
      width: 100%;
}
.deal-main[data-v-2e917d81] {
    width: 100%;
}
.deal-main .deal-content[data-v-2e917d81] {
      width: calc(100% - 400px);
}
.deal-main .deal-content .deal-title[data-v-2e917d81] {
        width: 100%;
}
.deal-main .deal-content .deal-tabs[data-v-2e917d81] {
        width: 100%;
}
.deal-main .deal-logs[data-v-2e917d81] {
      width: 400px;
}
}
